import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Helpers/seo'
import Welcome from '../components/Welcome'

import './solution-page.scss'

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        image
      }
    }
  }
`

const solutionTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  
  return(
    <Layout>
      <SEO
        title="Soluções"
        description={ frontmatter.description }/>
      
      <Welcome
        subtitle="Soluções"
        subtitleLink="/solucoes"
        image={ frontmatter.image }
        title={ frontmatter.title } />

      <div className="solution-page">
        <div
          className="solution-page__content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}


export default solutionTemplate


